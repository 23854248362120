import './wdyr';
import '@babel/polyfill';
import createCache from '@emotion/cache';
import { ErrorBoundary } from '@local/error-logging';
import { MetricsWrapper } from '@local/metrics';
import { theme } from '@local/web-design-system';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { LicenseManager } from 'ag-grid-enterprise';
import ReactDOM from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from 'store';
import { TssCacheProvider } from 'tss-react';
import { ERROR_BOUNDARY_DETAILS, ERROR_BOUNDARY_MESSAGE } from 'state-domains/constants';
import {
    setBaseApiEndpoint,
    setBaseEndpoint,
    setBaseNotificationsUrl,
    setClassicMXDepositUrl,
} from 'state-domains/utils';
import { getConfig, ProvideConfig } from 'src/components/AppConfig';
import { ErrorScreen } from 'src/components/ErrorScreen';
import { messagesEnUs } from 'src/translations';
import { LaunchDarklyProviderWrapper } from 'src/index.utils';
import React from 'react';

import packageInfo from '../package.json';

import { getBaseApiUrl, getBaseUrl } from './routes';
import { initStore } from './state';
import { initializeMixPanel, loadUserLocale } from './utilities';

// We only send data to sentry and datadog for release builds (NODE_ENV production), not dev builds.
const recordMetrics = process.env.NODE_ENV === 'production';
// eslint-disable-next-line no-console
console.log(process.env.NODE_ENV);
const reduxStore = initStore();

const datadogConfig = {
    appId: '8f3c1e92-8d06-40d1-9854-9e9b7c75fbd5',
    version: packageInfo.version,
    service: 'mx-deposit',
    clientToken: 'pubc7f04a9f19da5d82eaeb343acee684f8',
};

const sentryDSN =
    'https://6aadf9f9dbf342b989d36b1766002d2e@o189631.ingest.sentry.io/4505235203162112';

const locale = loadUserLocale();
const messages: Record<string, typeof messagesEnUs> = {
    'en-us': messagesEnUs,
};

LicenseManager.setLicenseKey(
    'Using_this_{AG_Grid}_Enterprise_key_{AG-061197}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Bentley_Systems,_Inc._(USA,_770_Pennsylvania_Drive)}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{MX_Deposit}_only_for_{8}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{MX_Deposit}_need_to_be_licensed___{MX_Deposit}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{4_August_2025}____[v3]_[01]_MTc1NDI2MjAwMDAwMA==2d5756280b45f5d5f4cddb87cadabdb3',
);

const basename = '/';

const onConfigLoaded = () => {
    const {
        location: { hostname, port },
    } = window;
    const baseApiUrl = getBaseApiUrl(hostname, port);
    const baseUrl = getBaseUrl(hostname, port);
    setBaseApiEndpoint(baseApiUrl);
    setBaseEndpoint(baseUrl);
    const config = getConfig();
    setBaseNotificationsUrl(config.notificationsUrl);
    setClassicMXDepositUrl(config.classicMXDepositBaseUrl);
    if (config.mixpanel?.token) {
        initializeMixPanel(config.mixpanel.token);
    }
};

const errorScreen = () => (
    <ErrorScreen msg={ERROR_BOUNDARY_MESSAGE} details={ERROR_BOUNDARY_DETAILS} />
);

const tssCache = createCache({
    key: 'tss',
});

const sentryReleaseVersion = packageInfo.version;

// If called after the provider has loaded the config this should
// get the config from session storage and have correct data. Any
// children of ProvideConfig should be fine
const getSegmentWriteKey = () => getConfig()?.segment?.writeKey;

function beforeSend(event: any) {
    // Add userId and subscription in username to the event if available
    const user = store.get('userContext');
    if (user?.id) {
        event.user = { id: user.id, username: user.subscriptionId };
    }
    return event;
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <ProvideConfig useUserPortalConfigJson onConfigLoaded={onConfigLoaded}>
            <TssCacheProvider value={tssCache}>
                <MuiThemeProvider theme={theme}>
                    <ErrorBoundary
                        errorComponent={errorScreen()}
                        releaseVersion={sentryReleaseVersion}
                        sentryDSN={recordMetrics ? sentryDSN : undefined}
                        beforeSend={beforeSend}
                    >
                        <MetricsWrapper
                            segmentWriteKey={getSegmentWriteKey()}
                            datadogConfig={recordMetrics ? datadogConfig : undefined}
                        >
                            <Provider store={reduxStore}>
                                <BrowserRouter basename={basename}>
                                    <IntlProvider
                                        locale={locale}
                                        defaultLocale={locale}
                                        messages={messages[locale]}
                                    >
                                        <LaunchDarklyProviderWrapper />
                                    </IntlProvider>
                                </BrowserRouter>
                            </Provider>
                        </MetricsWrapper>
                    </ErrorBoundary>
                </MuiThemeProvider>
            </TssCacheProvider>
        </ProvideConfig>
    </React.StrictMode>,
);
